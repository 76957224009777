.containerTitle {
  display: flex;
  align-items: flex-start;
  gap: 17px;
  margin-bottom: 40px;
  flex-direction: column;
}

.containerCards {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 16px;
  padding: 16px 24px;
  box-shadow: 0px 4px 15px 0px #8990d226;
}

.reloadIcon {
  cursor: pointer;
}
.containerExcel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.containerTitleCard {
  display: flex;
  align-items: center;
  gap: 17px;
}
.balanceHistoryHeaderButton {
  background-color: transparent;
  color: #464646;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 9px 24px;
  border: 1px solid var(--Text-2, #aaaaaa);
}

.containerExcelMobile {
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  h2 {
    font-size: 16px;
  }
  .containerTitleCardMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    svg {
      height: 24px;
    }
  }
  .containerFiltersMobile {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 500px) {
  .containerExcelMobile {
    display: flex;
  }
  .containerExcel {
    display: none;
  }
  .buttonFilter {
    background: #f5fff7;
    padding: 9px 16px;
    color: #21a038;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: none;
  }
}

@media screen and (min-width: 760px) {
  .containerExcelMobile {
    display: none;
  }
  .containerExcel {
    display: flex;
  }
  .containerFiltersMobile {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
