.addUserModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.192);
  display: flex;
  align-items: center;
  justify-content: center;
  .addUserModalContainer {
    background-color: white;
    padding: 32px 40px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    text-align: center;
    align-items: center;

    color: #464646;

    .addUserModalContainerText {
      font-size: 14px;
      span {
        font-weight: 700;
      }

      :last-child {
        margin-top: 10px;
      }
    }

    .addUserModalContainerButtons {
      display: flex;
      gap: 14px;
      margin-top: 16px;

      .addUserModalContainerButton {
        width: 120px;
      }

      :last-child {
        background: transparent;
        color: #232323;
        border: 1px solid #23232340;
      }
    }
    h1 {
      color: #232323;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .addUserModal {
    .addUserModalContainer {
      width: calc(100vw - 32px);
    }
  }
}
