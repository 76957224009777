.containerTitle {
  display: flex;
  align-items: flex-start;
  gap: 9px;
  margin-bottom: 45px;
  flex-direction: column;
}

.containerSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.reloadIcon {
  cursor: pointer;
}
.containerWithFilters {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media screen and (max-width: 500px) {
  .containerTitle {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: 15px;
      white-space: nowrap;
    }
    .buttonFilter {
      font-size: 14px;
    }
    svg {
      height: 24px;
    }
  }
}
