@import "../../styles/vars";

.newsItem {
  width: 100%;
  box-shadow: 0px 4px 15px 0px #8990d226;
  padding: 32px 40px;
  border-radius: 16px;
  background-color: #fff;
  gap: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .newsItemButton {
    background: transparent;
    color: $accent-color;
    white-space: nowrap;
  }
  .newsItemLeft {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .containerTitle {
      display: flex;
      align-items: center;
      gap: 16px;

      .containerTitleId {
        color: #21a038;
      }
      .importantTitle {
        color: white;
        font-size: 12px;
        white-space: nowrap;
        padding: 4px 16px;
        background: linear-gradient(
          88.58deg,
          #83da4e 2.42%,
          #21a038 51.09%,
          #54acb8 95.68%
        );
        border-radius: 8px;
      }
    }
    .newsItemText {
      display: flex;
      flex-direction: column;
      gap: 8px;
      word-break: break-all;
    }
    p {
      color: $text-font;
    }

    h2 {
      text-transform: uppercase;
      span {
        color: $accent-color;
      }
    }

    .dateTekst {
      color: #aaaaaa;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.newsItemButtonMobile {
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .newsItem {
    padding: 24px 16px;
    width: 100%;
    .newsItemLeft {
      width: 100%;
    }
  }
  .newsItemDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .newsItemButtonMobile {
    display: block;
    background: transparent;
    color: $accent-color;
    white-space: nowrap;
  }
  .newsItemButton {
    display: none;
  }
  .containerTitle {
    flex-direction: column-reverse;
    align-items: start !important;
  }
}
