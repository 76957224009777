@import "../../styles/vars";

.notFoundContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(../../../public/images/Background.svg) no-repeat center center
    fixed;
  background-size: cover;
  .containerText {
    text-align: center;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    gap: 30px;

    .subtitle {
      color: #aaaaaa;
    }
    .button {
      background: $green-gradient;
      padding: 9px 16px;
    }
  }
}

@media screen and (max-width: 500px) {
  .notFoundContainer {
    img {
      width: 250px;
    }
  }
}
