.cardNumber {
  cursor: pointer;
  max-width: 230px;
  overflow-x: auto;
  white-space: nowrap;
  background: #21a038;
  padding: 4px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 27px;
}
