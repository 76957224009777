@import "../../styles/vars";

.footer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  border-top: 2px solid $border-colors;
  padding-top: 26px;
  margin-left: 12px;
  a {
    &:hover {
      color: black;
      border-radius: 2px;
    }
  }
  .containerTgChannel {
    display: flex;
    align-items: center;
    gap: 8px;

    .containerLinksFooter {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .subscripteLink {
      font-weight: 700;
      font-size: 12px;
      color: #21a038;
      padding: 6px 8px;
      background: #dfffe5;
      border-radius: 6px;
    }
  }
  a {
    color: $primary_font;
  }
  .containerFooterItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
