.contentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
  .containerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    .subTitle {
      text-align: center;
    }
    .containerSubtitleText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
    p {
      margin: 0px;
    }
  }
  .containerIconInteractiveModal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.containerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .cancelButton {
    background: transparent !important;
    color: #232323;
    font-weight: 600;
    border: 1px solid #23232340;
    padding: 9px 28px;
    white-space: nowrap;
  }

  .activeBtn {
    padding: 9px 38px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .containerText {
    gap: 5px;
  }
  .containerSubtitleText {
    span {
      font-size: 15px;
    }
  }
  button {
    font-size: 14px;
  }
}
