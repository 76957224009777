.containerFooter {
  min-height: 74px;
  display: flex;
  justify-content: flex-start;
  z-index: 2;
  position: absolute;
  bottom: -86px;
  min-width: 100%;
  max-width: 100%;
  .contentFooter {
    border-top: 2px solid #f3f3f3;
    margin: 0px 40px;
    width: 100%;
  }
  p {
    color: #aaaaaa;
    width: 100%;
    margin-top: 24px;
  }
  .textFirmName {
    background: linear-gradient(
      88.98deg,
      #83da4e 39.18%,
      #21a038 49.07%,
      #54acb8 58.12%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

@media screen and (max-width: 500px) {
  .containerFooter {
    display: none;
  }
}
