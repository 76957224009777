.wrapperCardDetails {
  padding: 32px 40px;

  .containerHeader {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;

    button {
      padding: 12px 25px;
      background: #f5fff7;
      border-radius: 8px;
      color: #21a038;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
    }
  }

  .containerCardDetails {
    width: 100%;
    background: white;
    padding: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .skeletonHeaderCardDetails {
      height: 20vh;
      width: 100%;
    }
  }
  .containerTopHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 52px;
    overflow: auto;
    .containerTopHeaderBoth {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .cardDetailsContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;

    gap: 40px;
    width: 100%;
    .containerWithCardBalance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .containerBalanceTxt {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
            88.58deg,
            #83da4e 2.42%,
            #21a038 51.09%,
            #54acb8 95.68%
          );
          border-radius: 4px;
        }
      }
    }
  }
  .containerTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
    margin-bottom: 20px;
  }
  .containerHeaderBottomMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .containerMenuCard {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 26px;
      .cardId {
        color: #aaaaaa;
        font-size: 14px;
        font-weight: 500;
      }
      .containerActions {
        display: flex;
        align-items: center;
        gap: 8px;
        .statusCard {
          padding: 4px 16px;
          font-weight: 600;
          border-radius: 4px;
        }
        .containerPauseCard,
        .containerCloseCard {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
      }
    }
  }

  .containerAccountInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 12px;
    .containerAccountIcon {
      width: 24px;
      height: 24px;
      background: linear-gradient(
        88.58deg,
        #83da4e 2.42%,
        #21a038 51.09%,
        #54acb8 95.68%
      );

      border-radius: 4px;
    }
    .containerIbanTitle {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 6px;
      span {
        color: #aaaaaa;
        font-weight: 500;
      }
      p {
        white-space: nowrap;
        max-width: 300px !important;
        overflow: auto !important;
      }
    }
  }
  .containerHeaderCard {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.containerAdditionalInfoCard {
  display: flex;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;

  .gaps {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .adress {
      font-size: 14px;
    }
  }
  .timeCreated {
    font-size: 14px;
  }
  .containerAddUser {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;

    .containerHeaderAddUser {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
    .bottonAddUser {
      padding: 4px 16px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 9px;
      border-radius: 8px;
      height: auto;
    }
  }
}
.containerSpend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.wrapperSpendsPeriod {
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    font-weight: 500;
  }
}

.containerLimitsTitle {
  display: flex;

  align-items: center;
  gap: 8px;
}

.cursorPointer {
  cursor: pointer;
}

.popOver {
  position: fixed;
}

.reloadIcon {
  cursor: pointer;
}

.saveButton {
  width: 100%;
}
.rightHeaderItems {
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerFiltersMobile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.containerExcelMobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.containerTitleCardMobile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.hideInMobile {
  display: flex !important;
}
.containerMobileBalance {
  display: none;
}
// .containerAccountIconMobile {
//   height: 18px;
//   display: none;
// }

@media screen and (max-width: 500px) {
  .wrapperCardDetails {
    .containerAccountInfo {
      justify-content: space-between;
      width: 100%;
    }
  }

  .cardDetailsContainer {
    flex-direction: column;
  }
  .containerHeader {
    h1 {
      white-space: nowrap;
      font-size: 28px;
    }

    button {
      white-space: nowrap;
    }
  }
  .containerHeaderBottomMenu {
    flex-direction: column;
  }
  .containerAdditionalInfoCard {
    flex-direction: column;
    gap: 32px;
  }
  .containerExcelMobile {
    display: flex;
  }
  .containerTitle {
    display: none !important;
  }
  .cardIban {
    white-space: break-spaces !important;
    font-size: 13px;
  }
  .wrapperCardDetails {
    padding: 32px;
    .containerHeaderBottomMenu {
      align-items: flex-start;
      .containerMenuCard {
        margin-bottom: 10px;
        width: 100%;
        gap: 15px;
      }
    }
  }

  .containerIbanTitle {
    align-items: flex-start !important;
  }
  .containerCardDetails {
    .containerTopHeader {
      gap: 32px;
    }
  }
  .hideInMobile {
    display: none !important;
  }
  .containerMobileBalance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        88.58deg,
        #83da4e 2.42%,
        #21a038 51.09%,
        #54acb8 95.68%
      );
      border-radius: 4px;
    }
    .mobileContainerBalance {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .reloadIconMobile {
      height: 20px;
    }
  }
  .timeCreated {
    font-size: 13px !important;
  }

  .titleBoldMobile {
    font-size: 15px;
    font-weight: 600;
  }
}

@media screen and (min-width: 760px) {
  .containerExcelMobile {
    display: none;
  }
  .containerTitle {
    display: flex;
  }
  .containerFiltersMobile {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
