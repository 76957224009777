.button {
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonDisabled {
  background: transparent;
  opacity: 0.45;
}
