.dashboardMain {
  padding: 30px 40px 36px 40px;
  .dashboardTitleContainer {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 7px;
    margin-bottom: 36px;

    .reloadIcon {
      cursor: pointer;
    }
    .title {
      color: black;
    }
  }
  .centerMainContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .dashboardMain {
    .centerMainContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboardMain {
    padding: 16px 16px 90px 16px;
  }
}
