.wrapperContainerCards {
  padding: 32px 40px;

  .containerNavCards {
    margin-bottom: 40px;

    h1 {
      margin-bottom: 24px;
    }
  }
  .containerCards {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 16px;
    padding: 16px 24px;
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .wrapperContainerCards {
    padding: 16px 16px 90px 16px;
  }
}
