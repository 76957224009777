.mainWrapper {
  padding: 32px 32px;

  // p {
  //   margin-bottom: 24px;
  // }
  .containerTable {
    padding: 16px 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #8990d226;
  }
}
.titleTable {
  margin-bottom: 24px;
}

@media screen and (max-width: 500px) {
  .mainWrapper {
    padding: 16px 16px 90px 16px;
  }
}
