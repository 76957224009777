.cardItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  padding: 0 40px;

  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #8990d226;
  min-height: 220px;
  height: 220px;
  width: 100%;

  .cardItemRight {
    min-height: 100%;
    min-width: 352px;
    height: 220px;
    border-radius: 16px;
    position: relative;

    div {
      box-shadow: 4px 0px 8px 0px #00000040;
    }
  }

  .cardItemLeft {
    display: flex;
    align-items: center;
    gap: 40px;
    margin: 40px 0;

    .cardItemSpan {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        color: #aaaaaa;
        background-color: #ececec;
        border-radius: 4px;
        padding: 2px 16px;
        height: fit-content;
        width: fit-content;

        white-space: nowrap;
        font-family: Noto Sans;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    .cardItemLeftHeader {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .issueCardButton {
        border: 1px solid #21a038;
        color: #21a038;
        max-width: 165px;
      }
      .cardItemTitle {
        display: flex;
        gap: 16px;
        align-items: center;
      }
    }
  }

  p {
    color: #aaaaaa;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    a {
      color: #21a038;
      display: flex;
      align-items: center;
    }
  }
}

.cardItemSpanMobile {
  display: none;
}

@media screen and (min-width: 1440px) and (max-width: 1880px) {
  .cardItem {
    width: 100%;
    zoom: 80%;
    h1 {
      font-size: 26px;
      white-space: nowrap;
    }
  }
}

.cardItemBackground {
  background-position: center;
  z-index: 5;
  max-height: 220px;
  height: 220px;
  min-width: 348px;
  max-width: 348px;
  width: 384px;
  position: relative;
  border-radius: 16px;

  .cardItemP {
    position: absolute;
    top: 68px;
    left: 26px;
    font-family: Astrolab;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.38px;
    color: white;
    .cardItemSpan {
      letter-spacing: "0.75rem";
      color: white;
      font-family: Astrolab;
    }
  }
  img {
    position: relative;
    top: 150px;
    left: 26px;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .cardItem {
    height: auto;
  }
  .cardItemTitle {
    display: flex;
    flex-direction: column-reverse;
    align-items: start !important;
    gap: 8px !important;
    h1 {
      white-space: nowrap;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .cardItem {
    position: relative;
    padding: 0 16px;
    .cardItemRight {
      position: absolute !important;
      right: -95px;
      scale: 30%;
      top: -83px;
    }
    .cardItemLeft {
      margin: 28px 0;
    }
  }
  .cardItemLeftHeader {
    max-width: calc(100vw - 32px);
  }
  .cardItemSpan {
    display: none !important;
  }
  .cardItemSpanMobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    div {
      display: flex;
      gap: 8px;
    }
    span {
      color: #aaaaaa;
      background-color: #ececec;
      border-radius: 4px;
      padding: 2px 16px;
      height: fit-content;
      width: fit-content;

      white-space: nowrap;
      font-family: Noto Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .cardItemBackgroundMobile {
    max-height: 100px;
    max-width: 160px;

    background-size: cover;
    z-index: 5;
    width: 384px;
    position: relative;
    border-radius: 8px;
    .cardItemP {
      position: absolute;
      top: 30px;
      left: 15px;
      font-family: Astrolab;
      font-size: 7px;
      font-weight: 400;
      line-height: 23.38px;
      color: white;
      .cardItemSpan {
        letter-spacing: "0.75rem";
        color: white;
        font-family: Astrolab;
      }
    }
    img {
      position: relative;
      top: 150px;
      left: 26px;
    }
  }
}
