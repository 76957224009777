.container {
  border-radius: 16px;
  min-width: 352px;
  height: 220px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  position: relative;

  .dataExpNumber {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .containerCardData {
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 72px 26px 25px 26px;
  }

  .dataExp {
    display: flex;
    align-items: center;

    gap: 6px;
  }
  .LOGO {
    height: 25px;
  }
  .cardNumber {
    span {
      font-size: 13px;
      font-family: "Astrolab";

      letter-spacing: 0.8px;
    }

    display: flex;
    gap: 8px;
  }

  .extraBold {
    font-weight: 600;
    font-family: "Astrolab";
    font-size: 12px;
  }
  .containerAddInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .container {
    min-width: fit-content;
    height: auto;

    .containerCardData {
      padding: 55px 26px 25px 26px;

      justify-content: space-around;
    }
    .copyIcon {
      height: 15px;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    .cardNumber {
      span {
        font-size: 11px !important;
      }
    }
    .dataExp,
    .extraBold {
      font-size: 10px !important;
      span {
        font-size: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .container {
    .dataExpNumber {
      gap: 0px;
    }
    .containerCardData {
      padding: 55px 26px 25px 26px;
    }
    .cardNumber {
      span {
        font-size: 8px !important;
      }
    }
    .dataExp,
    .extraBold {
      font-size: 8px !important;
      span {
        font-size: 10px !important;
      }
    }
  }
}

// .visa {
//   background-image: url("../../icons/cardsIcons/VisaCardBG.svg");
// }

// .mc {
//   background-image: url("../../icons/cardsIcons/MasterCardBG.svg");
// }

// .union {
//   background-image: url("../../icons/cardsIcons/UnionCardBG.svg");
// }

// .msMobile {
//   background-image: url("../../icons/cardsIcons/McMobile.svg");
// }
// .mcMobile {
//   background-image: url("../../icons/mobile/MC.svg");
// }
// .visaMobile {
//   background-image: url("../../icons/mobile/VisaMob.svg");
// }
// .upMobile {
//   background-image: url("../../icons/mobile/UPMob.svg");
// }
