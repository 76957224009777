.modalOverlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.5s ease-in-out;
}

.modal {
  display: flex;
  flex-direction: column;

  background: #fff;
  padding: 30px;
  border-radius: 16px;
  width: 720px;
  min-height: 40vh;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .modalTitle {
    margin-bottom: 40px;
  }
}

.sideSteps {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.stepRound {
  border-radius: 100%;
  background-color: #21a038;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.textInactive {
  color: #aaaaaa;
}

.stepInactive {
  border-radius: 100%;
  background-color: #aaaaaa;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.modalItemsGap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}
.modalContainer {
  width: 100%;
  display: flex;
  text-align: start;
  .singleStep {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    overflow: hidden;
  }
}

.step {
  display: none;
}

.stepTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  padding-left: 32px;
}

.stepLine {
  margin: 16px 0;
  width: 2px;
  background-color: #f3f3f3;
  height: 100%;
}
.continueBtn {
  width: 114px;
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
}

.secondStepAmounts {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      color: #aaaaaa;
      font-size: 12px;
    }
  }
}

.addAccountContainer {
  position: relative;
  .addAccountSpan {
    position: absolute;
    width: fit-content;
    bottom: -25px;
    right: 0;

    font-size: 12px;
    color: #aaaaaa;
    font-weight: 400;
  }
}

.stepTitleTextField {
  position: absolute;
  right: 0;
  top: 8px;
  width: 75%;
}

.validationP {
  color: #ff4d4d;
  font-size: 14px;
}

.transferModalInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 155px;
  overflow: auto;
  padding: 5px 0 0 32px;
}

.activeStep {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 100%;
  p {
    padding-left: 32px;
  }
  h1 {
    padding-left: 32px;
  }
}
.buttonsContainer {
  padding-left: 32px;
}

.backButton {
  color: #464646;
  width: 80px;
}

.buttonsStepsContainer {
  display: flex;
  justify-content: space-between;
  gap: 21px;
  padding-left: 32px;
  margin-bottom: 40px;
}
.addFieldButton {
  color: #232323;
  border: 1px solid #23232340;
  font-size: 12px;
  padding: 0px 21px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.removeFieldButton {
  position: absolute;
  left: -18px;
  top: -11px;
  background-color: transparent;
  color: #aaaaaa;
}
.closeButtonContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  .closeBtn {
    color: #232323;
    border: 1px solid #23232340;
    padding: 10px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 85px;
  }
}
.transferFormInput {
  padding-left: 32px;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .modalOverlay {
    height: calc(100dvh - 72px);
  }
  .modal {
    max-width: calc(100vw - 32px);
    overflow: scroll;
    padding: 32px 16px;
    max-height: calc(100dvh - 184px);
    margin-bottom: 10px;
  }
  .secondStepAmounts {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
    div {
      min-width: 50%;
      p {
        padding: 0;
      }
      h1 {
        padding: 0;
      }
    }
  }

  .stepTitleTextField {
    position: static;
    width: 100%;
    padding: 16px 0;
    overflow: hidden;
  }
  .buttonsStepsContainer {
    display: flex;
    flex-direction: column-reverse;
    .buttonsStepsContainerLeft {
      display: flex;
    }

    .addAccountContainer {
      display: flex;
      gap: 16px;
      align-items: center;
      .addAccountSpan {
        position: static;
      }
    }
  }
  .stepTitle {
    padding-left: 16px;
  }

  .activeStep {
    p {
      padding-left: 16px;
    }
    h1 {
      padding-left: 16px;
    }
  }
  .buttonsContainer {
    padding-left: 16px;
  }
  .transferFormInput {
    padding-left: 16px;
  }

  .transferModalInputsContainer {
    padding-left: 16px;
  }
  .buttonsStepsContainer {
    padding-left: 16px;
  }
}
