.profilePage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 40px;
  .profileTitle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media screen and (max-width: 500px) {
  .profilePage {
    padding: 16px 16px 90px 16px;
  }
}
