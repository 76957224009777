.containerFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 2px solid #f3f3f3;
  .containerWithItemsFiltering {
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 24px;
  }
  .item {
    flex-grow: 1;
    min-width: 0;
    font-size: 14px;
  }
  .clearFilters {
    background: transparent;
    border: 1px solid #232323;
    color: #232323 !important;
    font-weight: 600;
    padding: 9px 32px;

    font-size: 16px;
    color: white;
  }
  .containerButtonsFilter {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .applyFilters {
    padding: 9px 32px;
    background: #232323;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (max-width: 500px) {
  .containerFilters {
    .containerWithItemsFiltering {
      flex-direction: column;
      gap: 10px;
    }
    button {
      font-size: 14px;
      white-space: nowrap;
      padding: 6px 18px !important;
    }
  }
}
