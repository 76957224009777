.statusBadge {
  display: flex;
  padding: 3px 8px;
  border-radius: 4px;
  white-space: nowrap;
  width: 100px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
