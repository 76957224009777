.profileChangePas {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .profileChangeForm {
    padding: 32px 24px;
    border-radius: 16px;

    background-color: white;

    box-shadow: 0px 4px 15px 0px #8990d226;
    .profileChangeContainer {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .profileChangeInputs {
        display: flex;
        align-items: center;

        .passwordTitle {
          min-width: 230px;
        }
        .containerInput {
          .containerWith2Fa {
            display: flex;
            align-items: center;
            gap: 13px;
          }
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 8px;
          p {
            color: #ff4d4d;
            font-size: 12px;
          }
        }
        input {
          max-width: 450px;
        }

        p {
          min-width: 170px;
        }

        .profileChangeInputFA {
          display: flex;
          align-items: center;
          gap: 16px;
          input {
            width: 100%;
          }

          button {
            min-width: 108px;
          }
        }
      }
      .changeProfileButtons {
        display: flex;
        gap: 16px;
        button {
          width: 120px;
        }
      }
      .cancelBtn {
        background: transparent;
        color: #232323;
        border: 1px solid #23232340;
        border-radius: 8px;
      }
    }
  }
}

.twaPageTimer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21a0381a;
  color: #21a038;
  width: 108px;
  height: 42px;
  white-space: nowrap;
  border-radius: 8px;
}

.resendCode {
  width: 108px;
}
.containerWith2faInput {
  max-width: 320px;
  min-width: 320px;
}

@media screen and (max-width: 1100px) {
  .containerWith2faInput {
    max-width: 200px;
    min-width: 200px;
  }
}
@media screen and (max-width: 960px) {
  .containerWith2faInput {
    max-width: 100px;
    min-width: 100px;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .profileChangePas {
    padding: 24px 16px;
  }
  .profileChangeInputs {
    flex-direction: column;
    align-items: start !important;
    gap: 4px;
  }
  .changeProfileButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    button {
      width: 50% !important;
    }
  }
  .containerInput {
    width: 100%;
    .containerWith2Fa {
      flex-direction: column;
      min-width: 100%;

      button {
        width: 100%;
      }
    }
  }
  .profileChangeInputFA {
    width: 100%;
  }
  .twaPageTimer {
    width: 100%;
  }
}
