.containerFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-start;
  padding-bottom: 20px;

  .containerWithItemsFiltering {
    display: flex;
    justify-content: space-between;
    width: 50%;
    gap: 24px;
  }
  .item {
    flex-grow: 1;
    min-width: 0;
  }
  .applyFilter {
    padding: 9px 32px;
    background: #232323;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  .clearFilters {
    background: transparent;
    border: 1px solid #232323;
    color: #232323 !important;
    font-weight: 600;
    padding: 9px 32px;

    font-size: 16px;
    color: white;
  }
}

.containerFilterButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .containerFilters {
    .containerWithItemsFiltering {
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }
    .containerFilterButtons {
      button {
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}
