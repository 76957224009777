$primary_font: #232323;
$secondary-font: #aaaaaa;
$text-font: #464646;
$accent-color: #21a038;
$error-color: #ff4d4d;
$border-colors: #f3f3f3;
$green-gradient: linear-gradient(
  88.58deg,
  #83da4e 2.42%,
  #21a038 51.09%,
  #54acb8 95.68%
);

:root {
  --primary-font: #232323;
  --secondary-font: #aaaaaa;
  --text-font: #464646;
  --accent-color: #21a038;
  --error-color: #ff4d4d;
  --border-colors: #f3f3f3;
  --green-gradient: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
}
