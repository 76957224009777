.excelButton {
  background-color: transparent;
  color: #464646;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 9px 24px;
  border: 1px solid var(--Text-2, #aaaaaa);
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background: #464646;
    opacity: 1;
  }

  svg path {
    fill: #464646;
    transition: fill 0.3s ease;
  }

  &:hover svg path {
    fill: #ffffff;
  }
}
