.issueCardModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.11);

  z-index: 10;

  .issueCardModalContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    background-color: white;
    border-radius: 24px;

    .issueCardModalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .issueCardModalContainerMain {
      display: flex;
      gap: 40px;
      .issueCardModalContainerLeft {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .issueCardModalInformationContainer {
          gap: 16px;
          flex-direction: column;
          .link {
            color: #21a038;
          }
          .issueCardModalInformation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 2px solid #f3f3f3;

            &:last-child {
              border: none;
            }
          }
        }
      }
      .issueCardModalContainerRight {
        width: 380px;
      }
    }
  }
}

.limitAmountButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .limitAmountButton {
    color: black;
    border: 1px solid #23232340;
    white-space: nowrap;
    padding: 4px 12px;
  }
}

.issueCardFooter {
  display: flex;
  gap: 24px;

  button {
    width: 100%;
    background: linear-gradient(
      88.58deg,
      #83da4e 2.42%,
      #21a038 51.09%,
      #54acb8 95.68%
    );
  }

  span {
    color: #aaaaaa;
  }
  .issueCardFooterLeft {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.exitButton {
  &:hover {
    cursor: pointer;
  }
}
.cardDesktop {
  display: flex;
}
.cardMobile {
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .issueCardModal {
    display: flex;
    align-self: flex-start;
    height: calc(100dvh - 72px);
    bottom: 0;
    right: 0;
  }

  .issueCardModalContainer {
    position: absolute;
    left: 50%;
    top: calc(50% + 32px);
    transform: translate(-50%, -50%);
    height: fit-content;
    max-height: calc(100% - 115px);

    padding: 32px 16px !important;
    overflow: scroll;
  }

  .issueCardModalContainerMain {
    display: flex;
    flex-direction: column;
    max-width: 98vw;
    width: 89vw;
  }

  .issueCardModalContainerLeft {
    .issueCardModalInformationContainer {
      display: flex;
      flex-direction: column;
    }
  }
  .issueCardModalContainerRight {
    width: 100% !important;
  }
  .limitAmountButtons {
    display: flex;
    gap: 10px;
    .limitAmountButton {
      padding: 4px 8px;
      font-size: 12px;
    }
  }
  .cardMobile {
    display: flex;
    justify-content: space-between;
    align-items: end;
    div {
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: center;
      color: #21a038;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .cardDesktop {
    display: none;
    font-weight: 600;
    font-size: 16px;
  }
}

.containerHelperPriceCards {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 12px;
    color: #21a038;
  }
}

.containerQuestion {
  height: 24px;
}
.containerHelperTekst {
  p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  padding: 15px;
  .wrapperHelperTekst {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .itemTekstHelper {
      display: flex;
      align-items: center;
      gap: 15px;
      .priceBold {
        font-weight: 600;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
