.tableAccountName {
  display: flex;
  flex-direction: column;

  .tableAccountsNameTitle {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .tableAccountsNameIban {
    color: #464646;
  }
}

.tableSkeleton {
  width: 100%;
  height: 30vh;
}

.allAccountsHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .allAccountsHeaderLeft {
    display: flex;
    gap: 16px;
  }
}

.allAccountsTableHeader {
  display: flex;
  gap: 16px;
  width: 100%;
}

.popoverUsersContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .popoverUsersItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 46px;
    height: 16px;
    transition: all 0.1s ease-in-out;
    span {
      color: #21a038;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.mantine-stc9u5 {
  background-color: transparent !important;
}
.mantine-stc9u5[data-disabled] {
  background-color: transparent !important;
}

.topUpBtn {
  display: flex;
  gap: 5px;
  flex-direction: row;
  border: none;
  align-items: center;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .popoverUsersContainer {
    max-height: 500px;
    overflow: auto;
  }
  .allAccountsTableHeader {
    justify-content: space-between;

    button {
      min-width: 47%;
      padding: 5px;
    }
  }
  .allAccountsHeaderLeft {
    gap: 8px !important;
    align-items: center;
  }
}
