/// cards
.containerCardName {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  img {
    width: 39px;
    height: 24px;
  }
  span {
    max-width: 230px;
    overflow-x: auto;
    white-space: nowrap;
    background: #21a038;
    padding: 4px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
  }
}
.cardName {
  font-size: 14px;
  color: #21a038;
  padding: 2px 15px;
  background: #ececec;
  border-radius: 4px;
  cursor: pointer;
}
.dateText {
  color: #21a038;
  font-size: 14px;
}

.ibanAcc {
  :first-child {
    color: #aaaaaa;
    padding: 2px 15px;
    border-radius: 4px;
    background: #ececec;
    white-space: nowrap;
    // margin-right: ;
  }
  :last-child {
    white-space: nowrap;
  }
}

.customCheckbox {
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  max-width: 24px;
}

.customCheckbox:checked {
  background: #21a038 !important;
  border: none;
}

// others

.containerCodes {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    color: #21a038;
  }
  .copyIcon {
    cursor: pointer;
  }
}

.cursorPointer {
  cursor: pointer;
}

//импортант чтобы перебить стили другой библиотеки
.containerQuestion {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: transparent !important;
}

.statusCard {
  padding: 2px 16px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
}

.popupTitleBlock {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    background: transparent;
    color: #21a038;
    font-size: 12px;
    cursor: pointer;
  }
  button:hover {
    background: none;
  }
}

.containerUserName {
  display: inline-block;
  background-color: #ececec;
  align-items: center;
  justify-content: flex-start;
  color: #21a038;
  border-radius: 4px;
  max-width: calc(20ch + 35px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 17.5px;
}
.defaultTableFontSize {
  font-size: 12.5px;
}
