.buttonFilter {
  display: flex;
  align-items: center;
  background: #f5fff7;
  padding: 9px 16px;
  color: #21a038;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: none;

  .text {
    text-decoration: none;
  }

  .icon {
    margin-left: 8px;
  }
}

@media (max-width: 500px) {
  .buttonFilter {
    padding: 9px 25px !important;

    .text {
      text-decoration: underline;
    }
    .icon {
      display: none;
    }
  }
}
