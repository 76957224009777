@import "../../styles/vars";

.containerMenuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 8px 8px 12px;
  border-radius: 8px;
  color: $primary_font;

  &:hover {
    background: linear-gradient(
      88.58deg,
      #83da4e 2.42%,
      #21a038 51.09%,
      #54acb8 95.68%
    );
    color: white;
  }
  &:hover .priceAcc {
    color: white !important;
  }
  &:hover .subTitle {
    color: white !important;
  }

  &:hover .iconsMenu svg {
    fill: white !important;
  }

  .containerTitles {
    display: flex;
    flex-direction: column;
    .containerTitle {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .minus {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .priceAcc {
    color: #21a038;
    font-weight: 600;
    font-size: 18px;
  }
  .priceActive {
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
  .iconsMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: fill 0.3s;

    svg {
      fill: currentColor;
      transition: fill 0.3s;
    }
  }
  .priceTitle {
    display: flex;
    align-items: center;
    .price {
      color: $accent-color;
    }
  }
}
.activeMenuItem {
  background: var(--green-gradient);
  color: white;

  .subTitle {
    color: white !important;
  }

  .iconsMenu svg {
    fill: white !important;
  }
}

.subTitle {
  color: #aaaaaa !important;
}
.skeletonPrice {
  width: 50px;
  height: 44px;
}
.activeColor {
  color: white !important;
}

.menuBarSkeleton {
  width: 100%;
  height: 40px;
}
