.cardIssuanceContainer {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  .cardIssuanceItems {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .cardIssuanceFilters {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 410px;

    .cardIssuanceFilter {
      display: flex;
      flex-direction: column;
      gap: 8px;

      position: relative;

      .filterSearchIcon {
        position: absolute;
        bottom: 4px;
        right: 8px;
      }

      input {
        margin: 0;
      }

      label {
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        color: #464646;
      }

      .cardIssuanceButtonContainer {
        border: 1px solid;

        border: 1px solid #21a038;

        border-radius: 8px;
        overflow: hidden;
        width: fit-content;

        .activeButton {
          background-color: #21a038;
          color: white;
        }

        button {
          border-radius: 0px;
          padding: 10px 16px;
          background-color: transparent;
          color: #21a038;

          border-right: 1px solid #21a038;
          box-sizing: border-box;

          font-family: Noto Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1870px) {
  .cardIssuanceFilters {
    min-width: 320px;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .cardIssuanceContainer {
    max-width: 100vw;
    flex-direction: column;

    .cardIssuanceFilters {
      max-width: calc(100vw - 32px);
    }
  }
}
