@import "../../../styles/vars";

.twoFaPage {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(../../../images/Background.svg) no-repeat center center fixed;
  background-size: cover;
  .twoFaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    .twoFaTitle {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      p {
        text-align: center;
        color: $secondary-font;
      }

      h1 {
        color: $primary_font;
        text-align: center;
      }
    }

    .twoFaForm {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .twoFaFormContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 64px;
        width: 100%;

        .twoFaFormInput {
          position: relative;
          display: flex;
          width: 100%;

          .twoFaFormError {
            position: absolute;
            bottom: -20px;
            color: $error-color;
            font-size: 12px;
            white-space: nowrap;
          }

          input {
            width: 100%;
          }

          label {
            position: absolute;
            top: -25px;
            width: 200px;
          }
        }

        button {
          width: 108px;
          font-size: 16px;
        }
      }
      .twaPageTimer {
        width: 108px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #f5fff7;
        font-family: "Noto Sans";
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        height: 40px;
        color: $accent-color;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .twoFaPage {
    background: url(../../../images/BackgroundMobile.svg) no-repeat center
      center fixed;
    background-size: cover;
    .loginContainerRegistration {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 16px;
    }
    .twoFaContainer {
      padding: 60px 32px;
      width: 100%;
      max-width: 380px;
      .twoFaForm {
        gap: 12px;
      }
      .twoFaFormContainer {
        flex-direction: column;
        .twoFaButtons {
          width: 100%;
          .twaPageTimer {
            width: 100%;
          }
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

.primaryText {
  white-space: nowrap;
}

.titleBackLogin {
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  color: #aaaaaa;
  a {
    margin-left: 4px;
    color: #21a038;
  }
}
.loginContainerRegistration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 16px 32px;
  margin-top: 20px;

  background-color: white;
  box-shadow: 0px 4px 15px 0px #8990d226;
  border-radius: 16px;

  .loginContainerRegistrationText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 60%;
    .loginRecoverLink {
      color: $accent-color;
    }
  }
  .loginContainerRegistrationButton {
    a {
      display: flex;
      align-items: center;
      color: white;
      gap: 8px;
      padding: 9px 16px;
    }
  }
}
