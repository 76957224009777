.containerDashboard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  width: 100%;

  .containerWidgets {
    display: grid;
    // grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 20px;
  }
  .widgetSkeleton {
    width: 50px;
    height: 44px;
  }
  .widgetSkeletonWithCard {
    width: 220px;
    height: 44px;
  }
  .containerImgCard {
    display: flex;
    align-items: center;
    gap: 28px;
    .cardText {
      color: #aaaaaa;
    }
    .twoRowsText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .conainerAllCards {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    p {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.containerTableDashboard {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 16px;
  background: white;
  padding: 16px 24px;
  box-shadow: 0px 4px 15px 0px #8990d226;
}

@media (max-width: 1800px) {
  .containerWidgets {
    grid-template-columns: repeat(2, 1fr);
  }

  .containerWidgets > :nth-child(5) {
    grid-column: span 2;
  }
}

@media (max-width: 500px) {
  .containerDashboard {
    .containerWidgets {
      display: flex;
      flex-direction: column;
    }
  }
  .containerWidgets > :nth-child(5) {
    display: flex;
    gap: 20px !important;
  }
}
