.containerPaymentProcessedModal {
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: flex-start;

  .containerTItleText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    .paragraphTitle {
      color: #464646 !important;
    }
    .minutesWithinTitle {
      color: #21a038;
      text-decoration: underline;
    }
  }
  .containerHeaderTitle {
    display: flex;
    align-items: flex-start;
    gap: 6px;

    .titleModal {
      font-weight: 700;
      font-size: 26px;
      word-break: keep-all;
    }
  }
  .containerCloseBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    button {
      font-weight: 700;
      padding: 7px 21px;
      font-size: 16px;
      border: 1px solid #23232340;
      background: transparent;
      color: #232323;
    }
  }
}

@media screen and (max-width: 500px) {
  .titleModal {
    font-size: 20px !important;
  }
  .paragraphTitle,
  .minutesWithinTitle {
    font-size: 14px !important;
  }
}
