@import "../../styles/vars";

.sideBar {
  background-color: white;
  position: fixed;
  min-width: 290px;
  height: 100%;
  z-index: 5;
  .logoContainer {
    padding: 0px 24px 24px 0px;
    width: fit-content;
    border-bottom: 2px solid #f3f3f3;
    width: 100%;
  }
  .sideBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 32px 16px;

    height: 100%;
  }
  .sideBarNavigation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 26px;
  }
}

@media screen and (max-width: 500px) {
  .sideBar {
    display: none;
  }
}
