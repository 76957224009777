.mainLayout {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  flex-grow: 1;
  overflow: auto;
}

.wrapperMainLayout {
  display: flex;
  flex: row;
  height: 100%;
  width: 100%;
}

.childrenLayout {
  position: absolute;
  left: 290px;
  width: calc(100% - 290px);
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 86px;
}
.containerLayout {
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.headerMobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .childrenLayout {
    width: 100vw;
    position: static;
    overflow: scroll;
  }
  .containerLayout {
    height: calc(100vh - 132px);
    top: 60px;

    min-height: auto;
  }
  .headerMobile {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    background: white;
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    right: 0;

    .containerHeaderMobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 32px);
    }
  }
}
