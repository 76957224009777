.cardLimitTitle {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  span {
    margin-bottom: 18px;
  }
}
.containerInputsLimitsModal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  input {
    width: 100% !important;
  }
}

.containerBtns {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  .applyBtn {
    padding: 9px 36px;
  }
  .closeBtn {
    padding: 9px 36px;
    background: transparent;
    color: black;
    border: 1px solid #23232340;
    border-radius: 8px;
  }
}

@media screen and (max-width: 500px) {
  .containerInputsLimitsModal {
    gap: 18px;
  }
}
