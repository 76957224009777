.skeletonTable {
  width: 100%;
  height: 40vh;
}
.issueCard {
  padding: 9px 16px;
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
  color: white;
  border-radius: 8px;
}

.cardsBtnsFunctional {
  padding: 0px 16px;
  border: 1px solid #f79e1b;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  color: #f79e1b;
}

.closeCard {
  padding: 0px 16px;
  border: none;
  font-size: 16px;
  background: #ff4d4d;
  color: white;
}

.containerBtnsInteractive {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 500px) {
  .containerBtnsInteractive {
    display: flex;
  }
  .containerBtnsInteractiveMobile {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .containerBtnsInteractive {
    display: none;
  }
  .containerBtnsInteractiveMobile {
    display: flex;
    button {
      font-size: 14px;
    }
  }
}
