.containerMobile {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 72px;
  background: white;
  z-index: 10000;
  box-shadow: 0px -4px 15px 0px #8990d226;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
  .itemMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    height: 44px;
  }

  p,
  a {
    font-weight: 500;
    font-size: 12px;
    color: #2f3035;
  }
  .itemMobileLast {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    height: 44px;
    z-index: 9999;
    cursor: pointer;
  }
  .active {
    color: #21a038;
  }
  .iconActive {
    fill: #ff6600;
  }
}
.containerAdditionalMenuItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.menuMobileTop {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.containerSubscripe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.containerTgChannel {
  display: flex;
  align-items: center;
  flex-direction: column;

  .subscripteLink {
    font-weight: 700;
    font-size: 8px;
    color: #21a038;
    padding: 3px 6px;
    background: #dfffe5;
    border-radius: 6px;
  }
}

.menuMobileBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-top: 16px;
  border-top: 2px solid #f3f3f3;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .containerMobile {
    display: none;
  }
}
