@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "./styles/_vars.scss";
@font-face {
  font-family: "Astrolab";
  src: url("./assets/fonts/Astrolab.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Noto Sans";
}

h1 {
  font-family: "Noto Sans";
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
}

h2 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

h3 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

button {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  height: 40px;
  color: white;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

button:hover {
  cursor: pointer;
  opacity: 0.8;
}

input {
  height: 40px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 9px 8px;
  background-color: #fff;
  color: #464646;
  &::placeholder {
    color: #aaaaaa;
  }
}

.buttons {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.tabsCategory {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.semiHedline {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.primaryText {
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: black;
}

.secondaryText {
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.H1 {
  font-family: "Noto Sans";
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
}

.H2 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.H3 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.body1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.body2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.skeleton {
  background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.Toastify {
  position: absolute;
}

.SH {
  font-weight: 600;
  font-size: 18px;
}
// TeamManagement, 2Dashboard
.mantine-kidyij[data-with-border],
.mantine-a5v3r0[data-with-border] {
  border: none !important;
  box-shadow: none;
}

// Accounts,TeamManagement, 2Dashboard
.mantine-qj59pk > tbody > tr > td,
.mantine-augnbd > tbody > tr > td {
  padding: 13px 1rem;
}
.react-select__single-value,
.react-select__placeholder {
  position: static !important;
  top: auto !important;
  margin: 0 !important;
  transform: none !important;
}

// timezone select container profile
@media screen and (max-width: 500px) {
  .css-b62m3t-container {
    width: 100% !important;
  }
}
.css-9c0mhj-indicatorContainer {
  cursor: pointer !important;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  h1 {
    font-family: "Noto Sans";
    font-size: 20px !important;
    font-weight: 700;
    line-height: 44px;
  }

  h2 {
    font-family: "Noto Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h3 {
    font-family: "Noto Sans";
    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .H1 {
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 44px;
  }

  .H2 {
    font-family: "Noto Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .H3 {
    font-family: "Noto Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }

  .semiHedline {
    font-family: "Noto Sans";
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
}

// Pagination button color
.mantine-1wc3ure[data-active] {
  background-color: transparent;
  background: linear-gradient(
    88.58deg,
    rgb(131, 218, 78) 2.42%,
    rgb(33, 160, 56) 51.09%,
    rgb(84, 172, 184) 95.68%
  );
  border: none;
}
//TeamManagement users
.mantine-r6q1cn {
  min-width: 210px;
}

//customAdornment
.css-1ro85z9-MuiTypography-root {
  color: #aaaaaa !important;
}

//m-ui input title fix
.css-w1u3ce {
  max-width: 0% !important;
}

.mantine-c3iz2m {
  max-width: 250px;
}

.mantine-zbh0b4[data-selected] {
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );

  color: white;
}

.mantine-1cn2mlo:focus {
  border-color: #21a038;
}
