.containerAllNotifs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 328px;
  position: absolute;
  top: 32px;
  right: 40px;

  .containerNotification {
    background: #2f3035;
    position: relative;
    border-radius: 16px;
    padding: 24px;
    max-width: 328px;
    .contentBlock {
      margin-bottom: 24px;
      margin-top: 15px;
    }
    .closeButton {
      position: absolute;
      top: 3px;
      right: 15px;
    }
    .notificationItem {
      .title {
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .content {
        color: white;
        font-size: 14px;
        max-width: 328px;
        font-weight: 500;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .containerDate {
      display: flex;
      color: white;
      align-items: center;
      justify-content: space-between;

      .btnMock {
        color: white;
        height: fit-content;
        font-size: 12px;
        padding: 4px 16px;
        background: linear-gradient(
          88.58deg,
          #83da4e 2.42%,
          #21a038 51.09%,
          #54acb8 95.68%
        );
      }
    }
  }
}

.preview {
  color: white;
  word-break: break-all;
}

@media screen and (max-width: 500px) {
  .containerAllNotifs {
    top: 12px;
    right: 20px;
    min-width: 270px;
    .containerNotification {
      max-width: 270px;
      padding: 15px;
    }
    .containerDate {
      p {
        font-size: 13px;
      }
    }
    .contentBlock {
      h4 {
        text-overflow: ellipsis;
        overflow: auto;
        margin-bottom: 8px;
        font-size: 13px;
      }
      p {
        text-overflow: ellipsis;
        overflow: auto;
        font-size: 13px;
      }
    }
  }
}
