.balanceHistoryContainer {
  background-color: white;
  border-radius: 16px;
  padding: 16px 24px;
  box-shadow: 0px 4px 15px 0px #8990d226;

  .balanceHistoryHeadernFilters {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .inputWidths {
      min-width: 23%;
    }
    .balanceHistoryHeaderMobile {
      display: none;
    }
    .balanceHistoryHeader {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        gap: 16px;
        align-items: center;
      }
      .balanceHistoryHeaderButton {
        background-color: transparent;
        color: #464646;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 9px 24px;
        border: 1px solid var(--Text-2, #aaaaaa);
      }
    }

    .balanceHistoryFilters {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 32px 0;
      border-bottom: 1px solid #f3f3f3;
    }
  }
}

.balanceHistoryHead {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #aaaaaa;
}
.balanceHistoryBody {
  tr {
    background-color: white;
    td {
      height: 56px;
      font-size: 14px;
      background-color: white;

      span {
        border-radius: 4px;
        background-color: #ececec;
        padding: 2px 5px;
        color: #aaaaaa;
        font-size: 12px;
      }

      .refillMethod {
        display: flex;
        color: green;
        width: 120px;
        justify-content: center;
      }
    }
  }
}

.skeletonTable {
  width: 100%;
  height: 20vh;
}
.applyFiltersButton {
  background-color: #232323 !important;
  padding: 9px 32px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.clearFiltersButton {
  background-color: transparent;
  border: 1px solid #232323;
  color: #232323 !important;
  font-weight: 600;
  padding: 9px 32px;

  font-size: 16px;
  color: white;
}
.datePickerInput {
  min-width: 23%;
  width: 23%;
}
.filterButtons {
  display: flex;
  gap: 16px;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .balanceHistoryContainer {
    .balanceHistoryHeadernFilters {
      .inputWidths {
        min-width: 100%;
      }
    }
  }
  .balanceHistoryHeaderMobile {
    display: flex !important;
    flex-direction: column;
    div {
      display: flex;
      gap: 12px;
    }
    .balanceHistoryHeaderButtonsMobile {
      margin-top: 16px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  .datePickerInput {
    min-width: 100% !important;
  }
  .balanceHistoryHeader {
    display: none !important;
  }

  .filterButtons {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    button {
      svg {
        display: flex;
      }
    }
  }
}
