.customContainer {
  top: 30px !important;
  right: 40px !important;
}
@media screen and (max-width: 500px) {
  .customContainer {
    max-width: 330px;
    word-wrap: break-word;
    white-space: normal;
    left: auto;
    top: 75px !important;
    right: 16px !important;
  }
}
