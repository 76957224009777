.modalBackground {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  // padding-top: 50px;
  background-color: rgba(0, 0, 0, 0.198);
  z-index: 10;
  overflow: auto;
  font-family: "Noto Sans";
  .containerWithAddedAccounts {
    max-height: 180px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .containerModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 32px);

    background: white;
    border-radius: 16px;
    width: 772px;
    padding: 32px 23px;
    box-shadow: 0px 4px 15px 0px #8990d226;
    height: fit-content;
    max-height: calc(100% - 40px);
    .containerInputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 20px;

      .inputsModal {
        width: 100%;
        font-family: "Noto Sans";
      }
    }

    .containerButtons {
      display: flex;
      flex-direction: column;
      gap: 18px;
      .containerInviteAndText {
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: flex-start;
      }

      .btnCloseModal {
        display: flex;
        justify-content: space-between;
        .submitBtn {
          padding: 7px 21px;
          color: white;
          background: linear-gradient(
            88.58deg,
            #83da4e 2.42%,
            #21a038 51.09%,
            #54acb8 95.68%
          );
          border: none;
          border-radius: 8px;
          font-family: "Noto Sans";

          white-space: nowrap;
        }

        button {
          font-family: "Noto Sans";

          padding: 7px 21px;
          border: 1px solid #23232340;
          color: #232323;
          background: white;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
    .textModalDescription {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  .mantine-1cn2mlo {
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
  .modalBackground {
    max-height: calc(100dvh - 72px);

    .containerModal {
      overflow: auto;
      max-height: calc(100dvh - 184px);
      top: calc(50% + 30px);
    }

    .containerWithAddedAccounts {
      max-height: 130px;
      overflow-y: auto;
      margin-bottom: 20px;
    }
  }

  .textModalDescription {
    font-size: 14px;
  }
  .textBottomInform {
    font-size: 14px;
  }
  button {
    font-size: 14px;
  }
}
