.modalOverlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 50px 0;
  transition: height 0.5s ease-in-out;
  &input,
  select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #fff;
  padding: 30px;
  overflow: auto;
  border-radius: 16px;
  width: 720px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 32px);
  color: #232323;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepIndicator {
  padding: 10px;
  background: #ddd;
  border-radius: 8px;
  font-weight: bold;
  width: 80px;
  text-align: center;
}

.stepIndicator.active {
  background: #34b4d0;
  color: white;
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: start;
  .singleStep {
    display: flex;
    flex-direction: column;

    max-height: 600px;
    gap: 12px;
    margin-left: 32px;
    width: 100%;
  }
}

.step {
  display: none;
}

.topUpNetworkList {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 21px;
    height: 18px;
  }
}

.step.activeStep {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalItemsGap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

input {
  height: 40px;
}

.sideSteps {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.stepRound {
  border-radius: 100%;
  background-color: #21a038;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.textInactive {
  color: #aaaaaa;
}

.stepInactive {
  border-radius: 100%;
  background-color: #aaaaaa;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.stepTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stepLine {
  margin: 16px 0;
  width: 2px;
  background-color: #f3f3f3;

  height: 100%;
}
.continueBtn {
  width: 114px;
  color: white;
  border: none;
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
  padding: 7px 21px;
  height: fit-content;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 32px 32px;
  position: relative;
  .firstStepOutOff {
    position: absolute;
    bottom: -25px;
    right: 0;
    color: #aaaaaa;
    font-size: 12px;
    font-weight: 400;
  }
  .addAccountBtn {
    color: #232323;
    border: 1px solid #23232340;
    padding: 10px 21px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
  }
  // :last-child {
  //   color: #232323;
  //   border: 1px solid #23232340;
  //   padding: 10px 21px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 12px;
  //   font-weight: 600;
  // }
}

.buttonsStepsContainer {
  display: flex;
  gap: 21px;
  :last-child {
    color: #464646;
  }
}
.closeBtnContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  .closeBtn {
    color: #232323;
    border: 1px solid #23232340;
    padding: 7px 21px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 32px;
  }
}

label {
  text-align: left;
  font-weight: bold;
}

.accountFormContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .deleteButton {
    position: absolute;
    padding: 0;
    max-width: 16px;
    height: 16px;
    z-index: 5;

    left: -40px;
    background-color: transparent;
    box-shadow: none;
    color: #aaaaaa;
    &:hover {
      box-shadow: none;
    }
  }
}

.thirdStepContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .thirdStepTransferInformation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .thirdStepTransferHeader {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .thirdStepContainerInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-height: 52px;

        h1 {
          font-size: 22px;
        }
      }
      .thirdStepFee {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        .thirdStepServiceFee {
          display: flex;
        }

        .thirdStepFeeContainer {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        .containerWithPrice {
          display: flex;
          align-items: center;
          gap: 1px;
        }
        span {
          display: flex;
          align-items: center;
        }
      }
    }

    .thirdStepAttention {
      display: flex;
      gap: 8px;
      flex-direction: column;
      padding: 16px 24px;
      background-color: #ffe2e2;
      border-radius: 16px;
      .thirdStepAttentionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .thirdStepAttentionTitleButton {
          transform: rotate(180deg);
        }

        button {
          height: fit-content;
          transition: transform 0.3s ease;
        }
      }
      .thirdStepAttentionText {
        span {
          color: #ff4d4d;
          font-weight: 700;
        }
      }
    }
  }
}

.copyIcon {
  margin-right: 8px;
}

.containerQuestion {
  height: 20px;
}

.scrolledItem {
  width: 100%;
  max-height: 250px;

  overflow: auto;
  display: flex;
}

.modalAccountsContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  max-height: 500px;
}

.firstStep {
  display: flex;
  flex-direction: column;
  .stepTitle {
    margin-left: 32px;
  }
}

.firstStepAccounts {
  margin-left: 32px;
}
.accountItem {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.displayNone {
  display: none;
}

.widgetSkeleton {
  margin: 2px 0 0 6px;
  min-width: 370px;
  height: 27px;
}

.firstStepOutOffMobile {
  display: none;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .modal {
    width: 100%;
    max-width: calc(100vw - 32px);
    overflow: auto;
    max-height: calc(100dvh - 184px);
    margin-bottom: 10px;
    padding: 32px 16px;
  }

  .buttonsContainer {
    flex-direction: column-reverse;
    gap: 16px;
    margin-left: 16px;
    .addAccountBtn {
      width: 136px;
      span {
        font-size: 13px;
        white-space: nowrap;
      }
    }
  }

  .thirdStepFee {
    flex-direction: column;
    span {
      font-size: 12px;
    }
  }
  .thirdStepContainerInput {
    max-width: 100%;
    display: flex;
    gap: 3px;
  }
  .thirdStepFeeContainer {
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  .widgetSkeleton {
    margin: 2px 0 0 6px;
    min-width: 100%;
    height: 27px;
  }

  .scrolledItem {
    max-height: 270px;
  }
  .closeBtnContainer {
    .closeBtn {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .singleStep {
    margin-left: 16px !important;
  }

  .firstStep {
    display: flex;
    flex-direction: column;
    .stepTitle {
      margin-left: 16px;
    }
  }

  .firstStepAccounts {
    margin-left: 16px;
  }
  .topUpTimer {
    font-size: 18px !important;
  }
  .addAccountMobile {
    display: flex;
    gap: 12px;
    align-items: center;
    .firstStepOutOffMobile {
      display: block;
      color: #aaaaaa;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .firstStepOutOff {
    display: none;
  }
}
